import React, { Suspense } from 'react';
import {
  AuditTrailRoutes,
  AuthRoutes,
  DashboardRoutes,
  FrontDeskRoutes,
  LogNewEntryRoutes,
  NotificationRoutes,
  PartnerRoutes,
  PatientRoutes,
  PaymentRoutes,
  ReferralRoutes,
  RolesRoutes,
  SupportRoutes,
  WaitlistRoutes,
  RebateRoutes,
  OnlineBookingsRoutes,
} from './Pages';
import VisitsRoutes from './Pages/Visits';

const AppRouter = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <React.Fragment>
        <AuthRoutes />
        <DashboardRoutes />
        <PatientRoutes />
        <FrontDeskRoutes />
        <ReferralRoutes />
        <AuditTrailRoutes />
        <WaitlistRoutes />
        <PaymentRoutes />
        <PartnerRoutes />
        <LogNewEntryRoutes />
        <NotificationRoutes />
        <SupportRoutes />
        <RolesRoutes />
        <VisitsRoutes />
        <RebateRoutes />
        <OnlineBookingsRoutes />
      </React.Fragment>
    </Suspense>
  );
};

export default AppRouter;
