/* eslint-disable no-unused-vars */
import { all } from 'redux-saga/effects';
import { watchAuditSaga } from './audit';
import { watchAuthSaga } from './auth';
import { watchPatientSaga } from './patients';
import { watchProfileSaga } from './profile';
import { watchReferralSaga } from './referral';
import { watchRoleSaga } from './roles';
import { watchUserSaga } from './users';
import { watchPartnerSaga } from './partner';
import { watchPaymentSaga } from './payment';
import { watchClinicianSaga } from './clinicians';
import { watchReportSaga } from './report';
import { watchSupportSaga } from './support';
import { watchNotificationSaga } from './notification';
import { watchBillingSaga } from './billings';
import { watchAdminDashboardSaga } from './adminDashboard';
import { watchVisitSaga } from './visits';
import { watchRebateSaga } from './rebate';
import { watchOnlineBookingsSaga } from './onlineBooking';

function* rootSaga() {
  yield all([
    watchAuthSaga(),
    watchProfileSaga(),
    watchPatientSaga(),
    watchRoleSaga(),
    watchAuditSaga(),
    watchUserSaga(),
    watchReferralSaga(),
    watchPartnerSaga(),
    watchPaymentSaga(),
    watchClinicianSaga(),
    watchReportSaga(),
    watchNotificationSaga(),
    watchBillingSaga(),
    watchAdminDashboardSaga(),
    watchSupportSaga(),
    watchVisitSaga(),
    watchRebateSaga(),
    watchOnlineBookingsSaga()
  ]);
}

export default rootSaga;
