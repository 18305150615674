import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { adminDashboardReducers } from './adminDashboard';
import { auditReducers } from './audit';
import { authReducer } from './auth';
import { clinicianReducer } from './clinicians';
import { notificationReducer } from './notification';
import { patientReducers } from './patients';
import paymentReducer from './payment/payment.reducer';
import { profileReducer } from './profile';
import { referralReducer } from './referral';
import { reportReducers } from './report';
import { roleReducers } from './roles';
import { supportReducers } from './support';
import { userReducer } from './users';
import visitReducers from './visits/visit.reducer';
import { rebateReducers } from './rebate';
import { onlineBookingsReducers } from './onlineBooking';

const appReducers = combineReducers({
  auth: authReducer,
  adminDashboard: adminDashboardReducers,
  profile: profileReducer,
  role: roleReducers,
  patient: patientReducers,
  audits: auditReducers,
  users: userReducer,
  referrals: referralReducer,
  clinicians: clinicianReducer,
  notifications: notificationReducer,
  payment: paymentReducer,
  reports: reportReducers,
  support: supportReducers,
  visits: visitReducers,
  rebates: rebateReducers,
  bookings: onlineBookingsReducers
});

export const rootReducer = (state, action) => {
  if (action.type === 'SIGNOUT') {
    storage.removeItem('persist:root');

    return appReducers(undefined, action);
  }

  return appReducers(state, action);
};
