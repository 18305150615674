import React, { useState, useEffect } from 'react';
import Modal from 'components/Modal/Modal';
import moment from 'moment';
import PropTypes from 'prop-types';
import styles from './frontdesk.module.scss';
import { ReactComponent as Close } from 'assets/icons/closeModal.svg';
import Button from 'components/Button/Button';
import SinglePageLoader from 'components/LoadingStates/SinglePageLoader';
import { getPackageDetailsAction } from 're-ducks/patients';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

const PackagesModal = ({
  open,
  isClose,
  onClick,
  updateData,
  setUpdateData,
  setPackageModal,
  referralIndividualName,
  referralIndividualID,
  referralInstitutionName,
  referralInstitutionID,
  paymentMethod,
  payingInstitutionID,
  packageID,
  enrolleeData,
  authoriziseData,
  displayData,
  setDisplayData
}) => {
  const [loading, setLoading] = useState(false);
  const [packageOption, setPackageOption] = useState([]);
  const dispatch = useDispatch();
  const added = [''];
  const displayedName = packageOption?.map((el) => el?.name).join(', ');
  let procedureData = updateData?.procedures;
  const onChangeDate = (e, index) => {
    const newDisplayData = [...displayData];
    const selected = newDisplayData[index];
    selected.scheduled_date = e.target.value;
    setDisplayData(newDisplayData);
  };
  useEffect(() => {
    let val = [];
    if (packageOption.length) {
      for (let el of packageOption) {
        if (!added.includes(el.id)) {
          const testItem = [];
          for (let test of el.tests) {
            testItem.push({
              ...test,
              package_id: el.id,
              total_amount: el.package_amount
            });
          }
          val = [...val, ...testItem];
        }
      }
      const data = val.map((item) => ({
        name: item?.name,
        value: item?.name,
        test_id: item?.test_unique_id,
        room: item?.room,
        scheduled_date: moment().format('YYYY-MM-DD HH:mm'),
        status: 'pending',
        package_id: item?.package_id,
        total_amount: item?.total_amount
      }));
      setDisplayData(data);
    }
  }, [packageOption]);
  useEffect(() => {
    setLoading(true);
    dispatch(
      getPackageDetailsAction(
        {
          params: {
            packages: [packageID]
          }
        },
        (res) => {
          setPackageOption(res);
          setLoading(false);
        },
        (error) => {
          toast.error(error);
          setLoading(false);
        }
      )
    );
  }, []);
  const handleNavigate = () => {
    setUpdateData({
      ...updateData,
      institution_name: referralIndividualName === 'Self' ? '' : referralIndividualName,
      referring_institution: referralInstitutionName === null ? '' : referralInstitutionName,
      referee_id: referralIndividualID,
      referring_institution_id: referralInstitutionID,
      paying_source: paymentMethod,
      paying_institution_id: payingInstitutionID,
      package_id: packageID,
      enrollee_id: enrolleeData,
      authorization_code: authoriziseData,
      procedures: (procedureData = displayData)
    });
    setPackageModal(false);
  };
  return (
    <div>
      <Modal isClose={isClose} isOpen={open} width={1000}>
        <div className={styles.chooseProcedure__header}>
          <h2 className={styles.chooseProcedure__title}>{displayedName}</h2>{' '}
          <div aria-hidden onClick={onClick} className={styles.icon}>
            <Close />
          </div>
        </div>
        <p className={styles.chooseProcedure__description}>
          Select procedures from the package and add the procedures to the summary.
        </p>
        <div className={styles.package__layout2}>
          {!loading ? (
            <div className={styles.package__procedureWrapper}>
              {displayData?.map((item, index) => {
                return (
                  <div key={item.test_id} className={styles.package__procedureItem}>
                    {item?.name}
                    <span className={styles.package__packageDate}>
                      <input
                        type="datetime-local"
                        value={item?.date}
                        style={{ background: '#fafafa' }}
                        onChange={(e) => onChangeDate(e, index)}
                        min={moment().format('YYYY-MM-DD HH:mm')}
                        defaultValue={moment().format('YYYY-MM-DD HH:mm')}
                      />
                    </span>
                  </div>
                );
              })}
            </div>
          ) : (
            <SinglePageLoader />
          )}
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <Button
              size="md_percent"
              theme="orange"
              type="submit"
              dataTestId="submit-package"
              onClick={() => handleNavigate()}
              loading={loading}>
              Continue
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
PackagesModal.propTypes = {
  open: PropTypes.bool,
  isClose: PropTypes.func,
  onClick: PropTypes.func,
  setUpdateData: PropTypes.func,
  updateData: PropTypes.object,
  setPackageModal: PropTypes.func,
  setCheck: PropTypes.bool,
  referralIndividualName: PropTypes.string,
  referralIndividualID: PropTypes.string,
  referralInstitutionName: PropTypes.string,
  referralInstitutionID: PropTypes.string,
  paymentMethod: PropTypes.string,
  payingInstitutionID: PropTypes.string,
  packageID: PropTypes.string,
  enrolleeData: PropTypes.string,
  authoriziseData: PropTypes.string,
  displayData: PropTypes.array,
  setDisplayData: PropTypes.func
};
export default PackagesModal;
